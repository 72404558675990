const apiUrl = "http://roxannesdemoapplication.one:8080"

async function getRequest(endpoint, params, username, password) {
    let queryEndpoint = endpoint;
    if (params !== null) {
        queryEndpoint = `${endpoint}?${params}`;
    }
    return await fetch(queryEndpoint, {
        method: 'GET',
        mode: 'cors',
        headers : {
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
          ...authHeader(username, password),
        }
    });
}

async function postRequest(endpoint, params, data, username, password) {
    let queryEndpoint = endpoint;
    if (params !== null) {
        queryEndpoint = `${endpoint}?${params}`;
    }
    return await fetch(queryEndpoint, {
        method: 'POST',
        mode: 'cors',
        headers : {
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
          "Content-Type": "application/json",
          ...authHeader(username, password),
        },
        body: JSON.stringify(data),
    }).then(resp => resp.json());
}

async function putRequest(endpoint, params, data, username, password) {
    let queryEndpoint = endpoint;
    if (params !== null) {
        queryEndpoint = `${endpoint}?${params}`;
    }
    return await fetch(queryEndpoint, {
        method: 'PUT',
        mode: 'cors',
        headers : {
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
          "Content-Type": "application/json",
          ...authHeader(username, password),
        },
        body: JSON.stringify(data),
    });
}

async function deleteRequest(endpoint, username, password) {    
    return await fetch(endpoint, {
        method: 'PUT',
        mode: 'cors',
        headers: {
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
            "Content-Type": "application/json",
            ...authHeader(username,password)
        }
    })
}

function toFEPointer(pointer) {
    let {latitude, longitude, ...rest} = pointer;
    return {...rest, latlng: {lat: pointer.latitude, lng: pointer.longitude}};
}

function toBEPointer(pointer) {
    let {latlng, ...rest} = pointer;
    return {...rest, latitude: pointer.latlng.lat, longitude: pointer.latlng.lng};
}

function authHeader(username, password) {
    let token = btoa(`${username}:${password}`);
    return {
      "Authorization" : `Basic ${token}`
    };
}

export {getRequest, postRequest, putRequest, deleteRequest, toFEPointer, toBEPointer, apiUrl};
import logo from './logo.svg';
import './App.css';
import { useState, useEffect } from 'react';
import LoginPage from './LoginPage';
import Map from './Map';


const isLoggedIn = () => {
  let username = localStorage.getItem("username");
  let password = localStorage.getItem("password");

  return true;
}

// every time the user sends a request, they will have to get the user and pass from local storage and send to the server.
// should do this in such a way that it could easily be swapped for a JWT method
function App() {

  let [loggedIn, setLoggedIn] = useState(false); 

  useEffect(() => {
    if (isLoggedIn()) {
      setLoggedIn(true);
    }
  }
  ,[])
  if (loggedIn) {
    return (<>
      <Map />
      <br/>
      <br/>
      <button className="login-button" onClick={() => setLoggedIn(false)}  >Log Out</button>
    </>)
  }
  else {
    return <LoginPage login={setLoggedIn} className=""/>
  }
}

export default App;

import { useState, useEffect } from 'react';
import { Marker, Popup } from 'react-leaflet';
import { putRequest, toBEPointer, toFEPointer, apiUrl } from './utils.js';

function CreationPointer() {

    return PointerContent
}

function PointerContent({data, editState, onEdit, onSubmit, onChangeText}) {
    if (editState === 'empty') {
        return (
             <Marker position={data.latlng}>
                 <Popup>
                     <p className="popup-text">{data.postBody}</p>
                     <p>Pointer Created By: {data.authorId}</p>
                     <button onClick={onEdit}>Edit Post</button>
                 </Popup>
             </Marker>
         );
     }
 
     if (editState === 'editing') {
         return (
             <Marker position={data.latlng}>
                 <Popup>
                     <input className="popup-input" value={data.postBody} type="text" onChange={onChangeText}></input>
                     <p>Pointer Created By: {data.authorId}</p>
                     <button onClick={onSubmit}>Save Changes</button>
                 </Popup>
             </Marker>
         );
     }
 
     // should we use useeffect to reset post after submitting?
     // why does the post disappear on submit?
     if (editState === 'submitting') {
         return (
             <Marker position={data.latlng}>
                 <Popup>
                     <p>Submitting...</p>
                 </Popup>
             </Marker>
         );
     }
 
     return  (
         <Marker position={data.latlng}>
             <Popup>
                 <p>Error</p>
             </Popup>
         </Marker>
     );
}


// Maybe: StaticPointer (for representing posts) and DynamicPointer (for creating posts)
// what have i learned? 
// check component prop names
// dont useState(prop.data) if prop.data may change



function Pointer({data}) {

    // will fail if data changes
    let [pointer, setPointer] = useState(data);

    // could be 'empty', 'editing', or 'submitting'
    let [editState, setEditState] = useState('empty');

    useEffect(() => {
        if (editState === 'submitting') {
            const submitData = async () => {
                try {
                    let resp = await putRequest(`${apiUrl}/api/pointer/${pointer.id}`, null, toBEPointer(pointer), localStorage.getItem("username"), localStorage.getItem("password"))
                             .then(rsp => rsp.json());
                    resp = toFEPointer(resp);
                    setPointer(resp);
                    setEditState('empty');
                } catch (err) {
                    // should show some error on error
                    console.error(err);
                    setEditState('empty');
                    // later, figure out how to 'reset' if edits fail.
                }
            }

            setTimeout(submitData, 1000);
        }
    }, [editState])

    const onChangeText = (e) => { setPointer({...pointer, "postBody": e.target.value}) };

    return <PointerContent data={pointer} editState={editState} onEdit={() => setEditState('editing')} onSubmit={() => setEditState('submitting')} onChangeText={onChangeText}/>
}


export {Pointer, PointerContent};
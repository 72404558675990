import { MapContainer, TileLayer, useMapEvent, Marker, Popup } from 'react-leaflet';

import { useState, useEffect } from 'react';

import { Pointer } from './Pointer.js'

import { getRequest, postRequest, toBEPointer, toFEPointer, apiUrl } from './utils.js';

const CENTER = [39.90158456316656, -75.6919584003479];

// :0000 onSubmit can be a function that exposes the pointer!!!!!!!
function PointerCreationLayer({onSubmit}) {
    
    // could be 'choosing pointer location', 'editing pointer content' or 'submitting pointer'
    let [pointer, setPointer] = useState(null);

    let username = localStorage.getItem("username");

    const map = useMapEvent('click', (e) => {
        setPointer({
            latlng: e.latlng,
            postBody:"",
            authorId: username,
            id: null,
            createdAt: null
        });
    });

    const changeText = (e) => {setPointer({...pointer, postBody: e.target.value})};


    // case 1: nothing has been picked
    if (pointer === null) return null;

    // case 3: have picked a pointer location, in 'editing' mode.
        return (<Marker position={pointer.latlng}>
        <Popup>
            <input value={pointer.postBody} type="text" placeholder="Your Post Here" onChange={changeText}></input>
            <p>Created by: {pointer.authorId}</p>
            <button onClick={() => onSubmit(pointer)}>Submit Post</button>
        </Popup>
    </Marker>);
}


function Map() {

    let [pointerList, setPointerList] = useState([]);

    // can be 'empty', 'create-pointer'

    let [actionState, setActionState] = useState('empty');

    let [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const data = await getRequest(`${apiUrl}/api/pointer`, null, localStorage.getItem("username"), localStorage.getItem("password"));
            const resp = await data.json();
            setPointerList(resp.map(ptr => toFEPointer(ptr)));
        }
    
        fetchData();
    },[])

    let innerJsx;
    if (actionState === 'empty') {
        innerJsx = pointerList.map((data) => <Pointer data={data} key={data.id} />);
    }

    if (actionState === 'create-pointer') {
        const onSubmit = (pointer) => {
            setActionState('empty');
            // after request posted, add pointer to list.
            postRequest(`${apiUrl}/api/pointer`, null, toBEPointer(pointer), "roxanne", "." )
                .then(resp => setPointerList([...pointerList, toFEPointer(resp)]))
                .catch((err) => setError(err));
        }
        // newpointer will do 2 things:
        // listen for a click to the map, and on click, setNewPointer
        innerJsx = <PointerCreationLayer onSubmit={onSubmit}/>;
        // this NewPointer needs to be given a function that will set the actionState to 'edit-pointer' and will set up 'newPointer'
    }

    return (<>
        <MapContainer center={CENTER} zoom={8} scrollWheelZoom={true} className="map-container" >
            <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                       url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            {innerJsx}
        </MapContainer>
        <button id="createButton" onClick={() => setActionState('create-pointer')}>Create Pointer</button>
        {error ? <p onClick={() => setError(null)}>{error}</p> : null}
    </>);
}

export default Map;
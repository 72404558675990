import { useState, useEffect } from 'react';
import { get, apiUrl } from './utils';

function LoginPage (props) {
    // when username and password fields are changed, update username and password variabled.
    // when 'login' button is pressed, try to login to backend (what does that mean?)
    // if that is successful, then save username and password to local storage.

    let [username, setUsername] = useState("");
    let [password, setPassword] = useState("");
    

    return (
    <div className="login-form">
        <div className="login-input">
            <label for="username">Username</label>
            <input name="username" onChange={(e) => setUsername(e.target.value)}></input>    
        </div>
        <div className="login-input">
            <label for="password">Password</label>
            <input name="password" type="password" onChange={(e) => setPassword(e.target.value)}></input>
        </div>
        <button className="login-button" onClick={() => login(username, password, props.login)}>Login</button>
        <p>Or</p>
        <button className="login-button" onClick={() => login("guest", "", props.login)}>Continue as Guest User</button>
    </div>);
}


// in the future this should get some kind of session token from backend
async function login(username, password, setLogin) {
    let result = await fetch(`${apiUrl}/api/user?user=${username}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Basic ${btoa(username + ':' + password)}`,
        }
    });

    if (result.ok) {
        setLogin(true);
        localStorage.setItem("username", username);
        // VERY VERY BAD CHANGE THIS
        localStorage.setItem("password", password);

        return true;
    }
    return false;
}

export default LoginPage;